import { Grid, GridItem } from '@chakra-ui/react';
import _ from 'lodash';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FwCard, FwContextMenu, FwInput, FwSpinner } from 'components/base';
import {
  getContextMenuItems,
  useGetDrag,
  useGetDrop,
} from 'components/base/containers/mask/FwMask.helpers';
import { FwMaskCommonProps } from 'core/model/props/FwMask.props';
import { FIELD_TYPE } from 'core/utils/constant';

import { Card, filterCards, formToCard } from './FwMask.Cards.helpers';

const FwCards: FC<FwMaskCommonProps> = ({
  dragEndDrop,
  maskStructure,
  maskRows,
  loading,
  processes,
  // functions
  handleOpen,
  handleProcessActionClick,
}) => {
  const { t } = useTranslation();
  const { view, dnd } = maskStructure;
  const { filterFieldKey, script } = dnd || {};

  const [cards, setCards] = useState<Card[]>(null);

  const prevmaskRows = useRef(maskRows);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (maskStructure && maskRows) {
      setCards(
        filterCards(
          _.map(maskRows, (mr) => formToCard(maskStructure, mr, t)),
          searchText
        )
      );
    }
  }, [maskStructure, maskRows, searchText, t]);

  useEffect(() => {
    if (!_.isEqual(maskRows, prevmaskRows.current)) {
      dragEndDrop.setDndLoading(false);
    }
    prevmaskRows.current = maskRows;
  }, [dragEndDrop, maskRows]);

  const updateArray = (array: any[], hoverIndex: number, maskRow: any) => {
    const newArray = array.filter((item) => item.key !== maskRow.key);
    newArray.splice(hoverIndex, 0, maskRow);

    return newArray;
  };

  const onHover = (
    hoverIndex: number,
    newFilterFieldKey: string,
    maskRow: any
  ) => {
    const canDrop =
      maskRow.viewContentFilter === filterFieldKey
        ? maskRows[hoverIndex]['key'] != maskRow.key
        : true;

    dragEndDrop.hover(hoverIndex, newFilterFieldKey, maskRow, script, canDrop);

    setCards((prevCards) => updateArray(prevCards, hoverIndex, maskRow));
  };

  const useDnd = (index: number, key: string, ref) => {
    const maskRow = _.find(cards, { key: key });

    const { isDragging, drag } = useGetDrag(
      'card',
      index,
      filterFieldKey,
      maskRow
    );
    const drop = useGetDrop('card', ref, index, filterFieldKey, onHover);

    drag(drop(ref));

    return isDragging;
  };

  return (
    <>
      {!view?.hideSearch && (
        <Grid alignItems="center" templateColumns="repeat(3, 1fr)">
          <GridItem>
            <FwInput
              search
              placeholder={t('common|Search...')}
              rightIcon={'RiSearchLine'}
              value={searchText}
              onChange={(
                _e: ChangeEvent,
                { value }: { name: string; value: string; fillData?: object }
              ) => setSearchText(value)}
              type={FIELD_TYPE.text}
              mb={2}
            />
          </GridItem>
        </Grid>
      )}
      <Grid>
        {!loading && cards ? (
          _.map(cards, ({ key, title, color }, index) => {
            const id = key || String(index);
            const openFunc = () => handleOpen(id);

            // define context menu items
            const contextItems = getContextMenuItems(
              t,
              openFunc,
              () => handleOpen(id, true),
              processes,
              (processId: string) => handleProcessActionClick(processId, id)
            );

            return (
              <GridItem key={id} my={2}>
                <FwContextMenu
                  renderTag="div"
                  childrenRenderTag="div"
                  items={contextItems}
                >
                  <FwCard
                    key={key}
                    id={id}
                    borderColor={color}
                    drop={dragEndDrop.drop}
                    content={_.map(title.split('\n'), (s) => [s])}
                    index={Number(index)}
                    searchText={searchText}
                    onClick={openFunc}
                    useDnd={useDnd}
                  />
                </FwContextMenu>
              </GridItem>
            );
          })
        ) : (
          <FwSpinner />
        )}
      </Grid>
    </>
  );
};

FwCards.propTypes = {};

export default FwCards;
