import _ from 'lodash';
import { arrayOf, InferProps, number, string, func } from 'prop-types';
import React, { FC, useEffect, useRef } from 'react';

import { FwArticle, FwHighlight, FwSegment, useFwTheme } from 'components/base';

const FwCard: FC<Props> = ({
  id,
  index,
  content,
  borderColor,
  searchText,
  onClick,
  useDnd,
  drop,
}) => {
  const { accent } = useFwTheme();

  const ref = useRef(null);
  const wasDraggingRef = useRef(false);

  const isDragging = useDnd(index, id, ref);

  useEffect(() => {
    if (isDragging) {
      wasDraggingRef.current = true;
    } else if (wasDraggingRef.current && !isDragging) {
      wasDraggingRef.current = false;
      drop();
    }
  }, [drop, isDragging]);

  const highlightedContent = searchText
    ? _.map(content, (texts) =>
        _.map(texts, (text) => (
          <FwHighlight key={text} text={text} highlight={searchText} />
        ))
      )
    : content;

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.1 : 1 }}>
      <FwSegment borderColor={borderColor || accent} onClick={onClick}>
        <FwArticle small content={highlightedContent} />
      </FwSegment>
    </div>
  );
};

const propTypes = {
  id: string,
  index: number,
  borderColor: string,
  content: arrayOf(arrayOf(string)),
  searchText: string,
  onClick: func,
  useDnd: func,
  drop: func,
};

export type Props = InferProps<typeof propTypes>;

FwCard.propTypes = propTypes;

export default FwCard;
